import { BaseChartDirective } from 'ng2-charts';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as XLSX from 'xlsx';
import 'hammerjs';
import * as zoomPlugin from 'chartjs-plugin-zoom';

@Component({
  selector: 'app-chartjs',
  templateUrl: './chartjs.component.html',
  styleUrls: ['./chartjs.component.scss'],
})
export class ChartjsComponent implements OnInit {
  constructor(private cdr: ChangeDetectorRef) {}

  chart = [];
  uploadData;
  updataHeader;
  tableview = false;
  date: any;
  dataA = [];
  min = 0;
  max = 10;
  bdFirst = 0;
  bdSecond = 0;
  @ViewChild("baseChart") charts: BaseChartDirective;
  public chartColors: Array<any> = [];
  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];

  public lineChartOptions: ChartOptions =  {
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
          scaleLabel: {
            display: true,
            labelString: 'Frequency Rate',
          },
          id: '1',
          ticks: {
            fontColor: "blue",
            beginAtZero: true,
            display: true,
            autoSkip: true,
             maxTicksLimit: 4,
             min: 0,
              max: 20
          },
        },
        {

          display: true,
          position: 'right',
          gridLines: {
            color: 'rgba(10, 0, 0, 0)',
          },
          id: '2',
          ticks: {
            fontColor: "blue",
            maxTicksLimit: 4,
            beginAtZero: true,
            display: true,
              autoSkip: true,
              min: 0,
              max: 20
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Month',
          },
          // type: 'time',
          // time: {
          //   displayFormats: {
          //      'millisecond': 'MMM DD',
          //      'second': 'MMM DD',
          //      'minute': 'MMM DD',
          //      'hour': 'MMM DD',
          //      'day': 'MMM DD',
          //      'week': 'MMM DD',
          //      'month': 'MMM DD',
          //      'quarter': 'MMM DD',
          //      'year': 'MMM DD',
          //   }}
          ticks: {
            display: true,
            autoSkip: true,
            // autoSkipPadding: 1,
            // sampleSize: 100,
             maxTicksLimit: 30,
            //  stepSize: 2,
          },
        },
      ],
    },
    plugins: {
     zoom: {
      pan: {
        // Boolean to enable panning
        enabled: true,
        // Panning directions. Remove the appropriate direction to disable
        // Eg. 'y' would only allow panning in the y direction
        mode: 'xy',
        rangeMin: {
          // Format of min pan range depends on scale type
          x: null,
          y: null,
        },
        rangeMax: {
          // Format of max pan range depends on scale type
          x: null,
          y: null,
        },
      },
      zoom: {
        enabled: true,
        drag: false,

        // Drag-to-zoom rectangle style can be customized
        // drag: {
        // 	 borderColor: 'rgba(225,225,225,0.3)'
        // 	 borderWidth: 5,
        // 	 backgroundColor: 'rgb(225,225,225)'
        // },

        // Zooming directions. Remove the appropriate direction to disable
        // Eg. 'y' would only allow zooming in the y direction
        mode: 'xy',
      //   limits: {
      //     max: 10,
      //     min: 0.5
      //  },
        rangeMin: {
          // Format of min zoom range depends on scale type
          x: null,
          y: null,
        },
        rangeMax: {
          // Format of max zoom range depends on scale type
          x: null,
          y: null,
        },

        // Speed of zoom via mouse wheel
        // (percentage of zoom on a wheel event)
        speed: 0.1,

        // Function called once zooming is completed
        // Useful for dynamic data loading
        onZoom: function ({ chart }) {
          console.log(`I was zoomed!!!`);
        },
      },
     }
    }
  };
  // tslint:disable-next-line: member-ordering
  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  // tslint:disable-next-line: member-ordering
  lineChartLegend = false;
  // tslint:disable-next-line: member-ordering
  lineChartPlugins = [];
  lineChartType = 'line';
  ngOnInit(): void {
  }

  // cahange file
  // tslint:disable-next-line: typedef
  incomingfile(evt) {
    this.uploadData = null;
    this.tableview = false;
    /* wire up file reader */
    const target: DataTransfer = evt.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      // tslint:disable-next-line: prefer-const
      let udata = XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.updataHeader = udata.shift();
      console.log('updataHeader', this.updataHeader);
      this.uploadData = udata;
      console.log('uploadData', this.uploadData);
      this.tableview = true;
      this.dateF();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  // tslint:disable-next-line: typedef
  dateF() {
    // tslint:disable-next-line: prefer-const
    let TimeArray = [];
    // tslint:disable-next-line: prefer-const
    let dataArray = [];
    let dateArray = [];
    // tslint:disable-next-line: prefer-for-of
    console.log('this.uploadData[i]', this.uploadData);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.uploadData.length; i++) {
      for (var j = 3; j < this.uploadData[i].length; j++) {
        if (dataArray.length < this.uploadData[i].length - 3) {
          let object1 = {
            column_id: j.toString(),
            column_array: [],
            column_header: '',
            column_min: 0,
            column_max: [10, 100, 1000]
          };

          dataArray.push(object1);
        }
      }

      for (var j = 0; j < this.uploadData[i].length; j++) {
        if (j == 0) {
          dateArray.push(this.uploadData[i][j]);
        } else if (j == 1) {
          TimeArray.push(this.uploadData[i][j]);
        } else if (j >= 3) {
          dataArray.forEach((element) => {
            if (element['column_id'] == j) {
              element['column_array'].push(this.uploadData[i][j]);
            }
          });
        }
      }
    }
    console.log('TimeArray', TimeArray);
    this.lineChartLabels = TimeArray;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < dataArray.length; i++) {
      for (let j = 0; j < this.updataHeader.length; j++) {
        if (dataArray[i]['column_id'] == j) {
          dataArray[i]['column_header'] = this.updataHeader[j];
        }
      }
    }
    console.log('this.dataArray', dataArray);
    this.lineChartData = [];
    dataArray.forEach((element) => {
      this.lineChartData.push({
          data: element['column_array'],
          label: element['column_header'],
          fill: false,
          borderDash: [this.bdFirst, this.bdSecond],
          yAxisID: element['column_id'],
          borderDashOffset: 10
        });
    });
    console.log('this.lineChartData', this.lineChartData);
    this.lineChartColors = [
      {
        borderColor: 'black',
        backgroundColor: 'rgba(255,255,0,0.28)',
      },
    ];

    this.lineChartOptions = {
      elements: {
        point: {
          radius: 0,
        },
      },
      responsive: true,
      scales: {
        yAxes
        : [
          {
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
            },
            scaleLabel: {
              display: true,
              labelString: 'Frequency Rate',
            },
            id: '3',
            ticks: {
              fontColor: "blue",
              beginAtZero: true,
              display: true,
              autoSkip: true,
               maxTicksLimit: 4,
              min: this.min,
              max: this.max
            },
           },
        ],
        xAxes: [
          {
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
            },
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Time',
            },
            ticks: {
              display: true,
              autoSkip: true,
              // autoSkipPadding: 0,
              // sampleSize: 0,
               maxTicksLimit: 4,
              //  stepSize: 0,
            },
          },
        ]
      },
      plugins: {
        zoom: {
         pan: {
           // Boolean to enable panning
           enabled: true,
           // Panning directions. Remove the appropriate direction to disable
           // Eg. 'y' would only allow panning in the y direction
           mode: 'xy',
           rangeMin: {
             // Format of min pan range depends on scale type
             x: null,
             y: null,
           },
           rangeMax: {
             // Format of max pan range depends on scale type
             x: null,
             y: null,
           },
         },
         zoom: {
           enabled: true,
           drag: false,

           // Drag-to-zoom rectangle style can be customized
           // drag: {
           // 	 borderColor: 'rgba(225,225,225,0.3)'
           // 	 borderWidth: 5,
           // 	 backgroundColor: 'rgb(225,225,225)'
           // },

           // Zooming directions. Remove the appropriate direction to disable
           // Eg. 'y' would only allow zooming in the y direction
           mode: 'xy',
         //   limits: {
         //     max: 10,
         //     min: 0.5
         //  },
           rangeMin: {
             // Format of min zoom range depends on scale type
             x: null,
             y: null,
           },
           rangeMax: {
             // Format of max zoom range depends on scale type
             x: null,
             y: null,
           },

           // Speed of zoom via mouse wheel
           // (percentage of zoom on a wheel event)
           speed: 0.1,

           // Function called once zooming is completed
           // Useful for dynamic data loading
           onZoom: function ({ chart }) {
             console.log(`I was zoomed!!!`);
           },
         },
        }
       }
    };
    this.lineChartOptions.scales.yAxes = [];
    dataArray.forEach((element) => {
      this.lineChartOptions.scales.yAxes.push(
        {
          gridLines: {
          color: 'rgba(0, 0, 0, 0)',
          },
          id: element['column_id'],
          display: true,
          position: 'right',
          ticks: {
             fontColor: 'blue',
             maxTicksLimit: 3,
             beginAtZero: true,
             display: true,
            autoSkip: true,
            min: this.min,
            max: this.max
          }
        }
      );
    });
    this.charts.chart.update();
    console.log('lineChartOptions', this.lineChartOptions.scales.yAxes);
    this.lineChartLegend = true;
    // tslint:disable-next-line: member-ordering
    this.lineChartPlugins = [zoomPlugin];
    this.lineChartType = 'line';
    console.log('data', dataArray);
  }
  downloadCanvas(event) {
    // get the `<a>` element from click event
    var anchor = event.target;
    // get the canvas, I'm getting it by tag name, you can do by id
    // and set the href of the anchor to the canvas dataUrl
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    // set the anchors 'download' attibute (name of the file to be downloaded)
    anchor.download = 'test.png';
  }
  onZoomChange() {
    if (this.charts !== undefined){
      this.charts.ngOnDestroy();
      this.charts.chart = this.charts.getChartBuilder(this.charts.ctx);
}
  }

}
