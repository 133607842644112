<input id="file-upload" type="file" accept=".csv,.xlsx"
                                            (change)="incomingfile($event)" />
<!-- <button (click)="inputUpdate()">Click</button> -->
<!-- {{lineChartOptions.zoom | json}}
{{lineChartOptions.scales.yAxes | json}} -->
<a href="#" (click)="downloadCanvas($event)"> DOWNLOAD THIS</a>
<div *ngFor="let item of lineChartOptions.scales.yAxes">
  <input type="number" (change)="onZoomChange()" [(ngModel)]="item.ticks.min" name="min{{item.id}}" id="min{{item.id}}">
  <input type="number" (change)="onZoomChange()" [(ngModel)]="item.ticks.max" name="max{{item.id}}" id="max{{item.id}}">
</div>
{{min}}
{{max}}
<div id="canvas" class="chartjs-block">
  <canvas baseChart #baseChart="base-chart"
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [colors]="lineChartColors"
      [legend]="lineChartLegend"
      [chartType]="lineChartType"
      [plugins]="lineChartPlugins"
      [colors]="chartColors">
  </canvas>
</div>

